// SearchableMultiSelect.js
import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  TextField,
  FormControl,
  Autocomplete,
  Box,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

const PipelineJobMultiSelect = ({
  selected,
  onSelectedChange,
  label,
  sx,
  jobNames = ["manual_image_tagging"],
}) => {
  const [options, setOptions] = useState([]);
  const componentRef = useRef(); // Create a ref for the component

  // Function to fetch filtered options from the database
  const fetchJobs = async () => {
    let headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/jobs/all-jobs`, headers)
      .then((res) => {
        // Reset form fields (optional)
        if (jobNames && jobNames.length > 0) {
          res.data.data = res.data.data.filter((x) =>
            jobNames.includes(x.name)
          );
        }
        setOptions(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, [jobNames]);

  return (
    <Box ref={componentRef} sx={sx}>
      <FormControl component="fieldset" variant="standard">
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            `[${option.pipeline_job_id}] ${option.pipeline_name}`
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {`[${option.pipeline_job_id}] ${option.pipeline_name} - ${option.name}`}
            </li>
          )}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              margin="none"
            />
          )}
          value={selected}
          onChange={(event, newValue) => {
            onSelectedChange(newValue);
          }}
        />
      </FormControl>
    </Box>
  );
};

export default PipelineJobMultiSelect;
