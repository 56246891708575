import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { useParams } from "react-router-dom";
import { ToolProvider, useToolContext } from "../../../../contexts/ToolContext";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import { PaginationButtonSet } from "../../../../components/ui/buttons/TableButtons";
import GarmentCard from "../../../../components/ui/cards/GarmentCard";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import SearchableMultiSelectV2 from "../../../../components/forms/Dropdowns/SearchableMultiSelectV2";
import StatusMultiSelect from "../../../../components/forms/Dropdowns/StatusMultiSelect";
import OrderByDropdown from "../../../../components/forms/Dropdowns/OrderBy";
import LoadingOverlay from "../../../../components/common/userFeedback/LoadingOverlay";
import StyledDateRangePicker from "../../../../components/forms/StyledDateRangePicker";
import StyledButton from "../../../../components/ui/buttons/StyledButton";
import NoDataScreenCover from "../../../../components/common/userFeedback/NoDataScreenCover";
import PipelineJobMultiSelect from "../../../../components/forms/Dropdowns/PipelineJobMultiSelect";

// -----------------------------
// Main Component
// -----------------------------
function PipelineJobsDataview() {
  const { jobDetails, pipelineJobId, jobDataLoaded, toolType } =
    useToolContext();
  const [rawProposals, setRawProposals] = useState([]); // Raw data from API
  const [toolInfo, setToolInfo] = useState(null); // Tool Info from middleware
  const [typeMapping, setTypeMapping] = useState("BRAND");
  const [page, setPage] = useState(1);
  const { palette } = useTheme();
  const [dataLoading, setDataLoading] = useState(false);
  const [dataExhausted, setDataExhausted] = useState(false);
  const [fixProposals, setFixProposals] = useState(false);
  const [orderBy, setOrderBy] = useState("DEFAULT");
  const [numberOfColumns, setNumberOfColumns] = useState(
    toolType && toolType === "IMAGE_TAGGING_QA" ? 8 : 4
  );
  const [useInternalSkip, setUseInternalSkip] = useState(false);
  const [limit, setLimit] = useState(12);

  // Existing filter states
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [proposalStatuses, setProposalStatuses] = useState([]);

  // Date range states
  const [proposalDateRange, setProposalDateRange] = useState([null, null]);
  const [qaDateRange, setQaDateRange] = useState([null, null]);
  const [additionalPipelineJobs, setAdditionalPipelineJobs] = useState([]);

  // State for modal visibility
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  useEffect(() => {
    setLimit(numberOfColumns * 3);
  }, [numberOfColumns]);

  const fetchToolData = async () => {
    setDataLoading(true);
    setToolInfo(null);

    const params = { toolType };
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/tool-info`,
        params,
        headers
      );
      setDataLoading(false);

      const words = response.data.data.name.replace("_", " ").split(" ");
      response.data.data.display_name = words
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ");
      if (response.data.data.display_name === "Image Class") {
        response.data.data.display_name = "Image Tag";
      }

      setTypeMapping(response.data.data.name.toUpperCase());
      setToolInfo(response.data.data);

      console.log(`SUCCESS toolInfo: ${JSON.stringify(response.data.data)}`);
    } catch (error) {
      console.error(`ERROR: ${error}`);
      setDataLoading(false);
      showAlert(error);
    }
  };

  const fetchData = async () => {
    setDataLoading(true);
    setRawProposals([]);

    const params = {
      page,
      limit,
      includeSkips: useInternalSkip,
      pipelineJobIds: [
        pipelineJobId,
        ...additionalPipelineJobs.map((job) => job.pipeline_job_id),
      ],
      graduationConfidence: jobDetails.qa_confidence,
      toolType,
      filters: {
        USERS: users.map((u) => u.id),
        LABELS: labels.map((l) => l.id),
        PROPOSAL_STATUSES: proposalStatuses.map((ps) => ps.name),
      },
      orderBy,
    };

    if (proposalDateRange[0]) {
      params.proposalDateRange = {
        start: proposalDateRange[0].toISOString(),
        end: proposalDateRange[1].toISOString(),
      };
    }
    if (qaDateRange[0]) {
      params.qaDateRange = {
        start: qaDateRange[0].toISOString(),
        end: qaDateRange[1].toISOString(),
      };
    }

    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/pipeline-job-dataview`,
        params,
        headers
      );

      setDataLoading(false);
      setRawProposals(response.data.data);

      setDataExhausted(response.data.data.length < limit);
      console.log(`SUCCESS: ${JSON.stringify(response.data.data)}`);
    } catch (error) {
      console.error(`ERROR: ${error}`);
      showAlert(error);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [users, labels, proposalStatuses, orderBy]);
  useEffect(() => {
    if (jobDataLoaded && !dataLoading) {
      fetchData();
    }
  }, [orderBy, page, limit]);

  useEffect(() => {
    if (jobDataLoaded && !dataLoading) {
      fetchToolData();
    }
  }, [jobDataLoaded]);

  const handleFixProposalsChange = (event) => {
    setFixProposals(event.target.checked);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoadingOverlay open={dataLoading} />
      {/* Configuration Modal */}
      <Dialog
        open={isConfigModalOpen}
        onClose={() => setIsConfigModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Configure Summon Options</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Row 1: Users and Label */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">Select Users</Typography>

              <SearchableMultiSelectV2
                typeMapping={"USER"}
                selected={users}
                onSelectedChange={setUsers}
                label={"Select Users"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">
                Select {toolInfo?.display_name || ""} Label
              </Typography>
              <SearchableMultiSelectV2
                typeMapping={toolInfo?.name?.toUpperCase() || ""}
                orderBy={toolInfo?.name}
                selected={labels}
                onSelectedChange={setLabels}
                label={`Select ${toolInfo?.display_name || ""} Label`}
              />
            </Grid>

            {/* Row 2: Proposal Status and Skips */}
            <Grid item xs={12} sm={4}>
              <Typography variant="body_md">Select Proposal Status</Typography>

              <StatusMultiSelect
                selected={proposalStatuses}
                onSelectedChange={setProposalStatuses}
                label={"Select Proposal Status"}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body_md">
                Select Additional Pipeline Jobs
              </Typography>
              <PipelineJobMultiSelect
                selected={additionalPipelineJobs}
                jobNames={[jobDetails?.job]}
                onSelectedChange={(newValue) =>
                  setAdditionalPipelineJobs(newValue)
                }
                sx={{
                  m: 0,
                  pt: 1.35,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", alignItems: "center", paddingLeft: 1 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useInternalSkip}
                    onChange={() => setUseInternalSkip(!useInternalSkip)}
                    color="primary"
                  />
                }
                label={<Typography variant="body_lg">Skips</Typography>}
                sx={{
                  "& .MuiTypography-root": { fontSize: "1rem" },
                }}
              />
            </Grid>

            {/* Row 3: Date Range Pickers */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">
                Select Date Range (Labelled)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">Select Date Range (QA)</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledDateRangePicker
                startText={"Proposal Start Date"}
                endText={"Proposal End Date"}
                value={proposalDateRange}
                onChange={(newValue) => setProposalDateRange(newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledDateRangePicker
                startText={"QA Start Date"}
                endText={"QA End Date"}
                value={qaDateRange}
                onChange={(newValue) => setQaDateRange(newValue)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => {
              fetchData();
              setIsConfigModalOpen(false);
            }}
          >
            Apply Filters
          </StyledButton>
          <StyledButton
            variant="text"
            color="primary"
            onClick={() => setIsConfigModalOpen(false)}
          >
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* Header with Title and Button to Open Modal */}
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Pipeline Job Data View</Typography>
        <StyledButton
          variant="outlined"
          color="primary"
          onClick={() => setIsConfigModalOpen(true)}
        >
          Configure Summon Options
        </StyledButton>
      </Box>

      {/* Main Data Grid */}
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <Grid
          container
          sx={{
            backgroundColor: palette.primary[300],
            width: "100%",
            p: 1,
            height: "100%",
          }}
          rowSpacing={0}
        >
          {rawProposals.map((garmentData) => (
            <Grid item xs={12 / numberOfColumns} key={garmentData.id}>
              <GarmentCard
                imageNum={garmentData.image_num}
                garmentData={garmentData}
                fixProposals={fixProposals}
              />
            </Grid>
          ))}
          {rawProposals.length === 0 && (
            <NoDataScreenCover showBackButton={false} />
          )}
        </Grid>
      </Box>

      {/* Footer Controls */}
      <Box
        sx={{
          height: 100,
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={6} sm={3} md={2} lg={1.5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fixProposals}
                onChange={handleFixProposalsChange}
                color="primary"
              />
            }
            label={<Typography variant="body_lg">Fix Proposals</Typography>}
            sx={{
              "& .MuiTypography-root": { fontSize: "1rem" },
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1}>
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
            <MenuItem value={9}>9 Columns</MenuItem>
            <MenuItem value={10}>10 Columns</MenuItem>
            <MenuItem value={11}>11 Columns</MenuItem>
            <MenuItem value={12}>12 Columns</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1} sx={{ ml: 1, mb: 0.5 }}>
          <OrderByDropdown setOrderBy={setOrderBy} orderBy={orderBy} />
        </Grid>
        <PaginationButtonSet
          setPage={setPage}
          page={page}
          disabled={dataLoading}
          nextPageDisabled={dataExhausted}
          previousPageDisabled={page === 1}
        />
      </Box>
    </Box>
  );
}

function WrappedPipelineJobsDataview() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <PipelineJobsDataview />
    </ToolProvider>
  );
}

export default WrappedPipelineJobsDataview;
