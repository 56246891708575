import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  Modal,
  Button,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { Dialog, CircularProgress } from "@mui/material";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { ToolProvider, useToolContext } from "../../../../contexts/ToolContext";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import { ViewButton } from "../../../../components/ui/buttons/TableButtons";
import HideableTable from "../../../../components/ui/HideableTable";
import StyledDataGrid from "../../../../components/ui/dataViews/StyledDataGrid";
import StyledDateRangePicker from "../../../../components/forms/StyledDateRangePicker";
import StyledButton from "../../../../components/ui/buttons/StyledButton";
import PipelineJobMultiSelect from "../../../../components/forms/Dropdowns/PipelineJobMultiSelect";
import { FormControlLabel, Checkbox, Grid } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";

const PlotlyRenderers = createPlotlyRenderers(Plot);
function ProcessTimestamps(data, timestampColumns, resolution) {
  return data.map((entry) => {
    timestampColumns.forEach((column) => {
      if (entry[column]) {
        let date;
        try {
          date = new Date(entry[column]);
        } catch {
          // If the timestamp is invalid, skip processing this column
          return;
        }

        switch (resolution) {
          case "Minutes":
            entry[column] = `${
              date.toISOString().split("T")[0]
            } ${date.getHours()}:${date.getMinutes()}`;
            break;
          case "Hours":
            entry[column] = `${
              date.toISOString().split("T")[0]
            } ${date.getHours()}:00`;
            break;
          case "Weeks":
            const startOfWeek = new Date(
              date.setDate(date.getDate() - date.getDay())
            );
            entry[column] = startOfWeek.toISOString().split("T")[0];
            break;
          case "Months":
            entry[column] = `${date.getFullYear()}-${String(
              date.getMonth() + 1
            ).padStart(2, "0")}`;
            break;
          case "Days":
          default:
            entry[column] = date.toISOString().split("T")[0];
            break;
        }
      }
    });

    return entry;
  });
}

function PipelineJobsPivotTable() {
  const [proposals, setProposals] = useState([]);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const {
    jobDetails,
    pipelineJobId,
    jobDataLoaded,
    toolType,
    allPipelineJobs,
  } = useToolContext();
  const [rawProposals, setRawProposals] = useState([]); // Raw data from API
  const { palette, typography } = useTheme();
  const [resolution, setResolution] = useState("Months"); // Default resolution to Days
  const timestampColumns = ["sold_timestamp", "listed_timestamp"];
  const itemsPerPage = 5000; // You can adjust the items per page as needed
  const [dataLoading, setDataLoading] = useState(true);
  const [otherJobs, setOtherJobs] = useState([]); // Other jobs in the pipeline
  const [proposalDateRange, setProposalDateRange] = useState([null, null]);
  const [qaDateRange, setQaDateRange] = useState([null, null]);
  const [additionalPipelineJobs, setAdditionalPipelineJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let tempotherJobs = [];
    allPipelineJobs.forEach((job) => {
      console.log(job.pipeline_job_link_id, pipelineJobId);
      if (parseInt(job.pipeline_job_link_id) !== parseInt(pipelineJobId)) {
        tempotherJobs.push(job);
      }
    });
    setOtherJobs(tempotherJobs);
  }, [allPipelineJobs]);

  useEffect(() => {
    // Assume fetchData fetches the raw data
    const processedData = ProcessTimestamps(
      rawProposals,
      timestampColumns,
      resolution
    );

    setProposals(processedData);
  }, [resolution, rawProposals]);
  useEffect(() => {
    resetData();
    console.log("jobDataLoaded", jobDetails);
  }, [jobDataLoaded, pipelineJobId]);

  const resetData = () => {
    setProposals([]);
    setRawProposals([]);
    setDataLoading(false);
  };
  const fetchData = async (page = 1) => {
    setDataLoading(true);
    let params = {
      page: page,
      limit: itemsPerPage,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      pipelineJobIds: [
        pipelineJobId,
        ...additionalPipelineJobs.map((job) => job.pipeline_job_id),
      ],
    };
    if (proposalDateRange[0]) {
      params.proposalDateRange = {
        start: proposalDateRange[0].toISOString(),
        end: proposalDateRange[1].toISOString(),
      };
    }
    if (qaDateRange[0]) {
      params.qaDateRange = {
        start: qaDateRange[0].toISOString(),
        end: qaDateRange[1].toISOString(),
      };
    }

    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/pipeline-job-user-pivot-data`,
        params,
        headers
      );

      setRawProposals((prev) => [...prev, ...response.data.data]);

      // Check if there are more items to fetch
      if (response.data.data.length === itemsPerPage) {
        await fetchData(page + 1); // Fetch next page
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch data", error);
      showAlert(error); // Automatically extracts the message and shows the alert
    }
  };

  const columns = [
    { field: "pipeline_job_link_id", headerName: "id", flex: 1 },
    { field: "pipeline_job_index", headerName: "Pipeline Index", flex: 1 },
    { field: "job", headerName: "Job Name", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      editable: false,

      renderCell: (params) => (
        <ViewButton
          action={() =>
            navigate(
              `/admin/pipeline-jobs-analytics/analytics/${params.row.pipeline_job_link_id}`
            )
          }
        />
      ),
    },
    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Dialog
        open={isConfigModalOpen}
        onClose={() => setIsConfigModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Configure Summon Options</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography variant="body_md">
                Select Additional Pipeline Jobs
              </Typography>
              <PipelineJobMultiSelect
                selected={additionalPipelineJobs}
                jobNames={[jobDetails?.job]}
                onSelectedChange={(newValue) =>
                  setAdditionalPipelineJobs(newValue)
                }
                sx={{
                  m: 0,
                  pt: 1.35,
                }}
              />
            </Grid>

            {/* Row 3: Date Range Pickers */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">
                Select Date Range (Labelled)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body_md">Select Date Range (QA)</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledDateRangePicker
                startText={"Proposal Start Date"}
                endText={"Proposal End Date"}
                value={proposalDateRange}
                onChange={(newValue) => setProposalDateRange(newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledDateRangePicker
                startText={"QA Start Date"}
                endText={"QA End Date"}
                value={qaDateRange}
                onChange={(newValue) => setQaDateRange(newValue)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => {
              fetchData();
              setIsConfigModalOpen(false);
              resetData();
            }}
          >
            Apply Filters
          </StyledButton>
          <StyledButton
            variant="text"
            color="primary"
            onClick={() => setIsConfigModalOpen(false)}
          >
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Typography variant="h3" color="primary.800">
            User Pivots
          </Typography>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsConfigModalOpen(true);
            }}
          >
            Configure Summon Options
          </StyledButton>
        </Box>

        <HideableTable
          containerStyle={{
            backgroundColor: palette.primary[100],
            color: palette.primary[800],
            mt: 1,
          }}
          columns={[
            {
              header: "Pipeline Name",
              field: "pipeline_name",
              align: "left",
            },
            {
              header: "Job Name",
              field: "job",
              align: "left",
            },
            {
              header: "Pipeline Index",
              field: "pipeline_job_index",
              align: "left",
            },
          ]}
          rows={[
            {
              pipeline_name: jobDetails?.pipeline_name,
              job: jobDetails?.job,
              pipeline_job_index: jobDetails?.pipeline_job_index,
            },
          ]}
        />

        <Box
          sx={{
            mt: 1,
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            minHeight: "50vh",
            overflowX: "auto",
            display: "flex",
          }}
        >
          <PivotTableUI
            data={proposals}
            onChange={setProposals}
            {...proposals}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
        <Typography variant="h3" color="primary.800" sx={{ mb: 1 }}>
          Related Pipelines
        </Typography>
        <StyledDataGrid
          rows={otherJobs}
          columns={columns}
          getRowId={(row) => row.pipeline_job_link_id}
          pageSize={5}
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}
function WrappedPipelineJobsPivotTable() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <PipelineJobsPivotTable />
    </ToolProvider>
  );
}

export default WrappedPipelineJobsPivotTable;
