import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";

import ClickableImage from "../../../components/layout/Interactive/Images/ClickableImage";
import { getHeaders } from "../../../utils/apiUtils";
import GarmentPanel from "./GarmentPanel";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { useParams } from "react-router-dom";
import ImageIndexButtons from "../../../components/ui/buttons/ImageIndexButtons";
import StyledImageCarousel from "../../../components/ui/StyledImageCarousel";

function AggregationTool(props) {
  const theme = useTheme();
  const [garments, setGarments] = useState([]);
  const [seedGarment, setSeedGarment] = useState(null);
  const [garmentID, setGarmentID] = useState(null);
  const [publicGarmentId, setPublicGarmentId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);
  const {
    inputFilters,
    jobDetails,
    toolType,
    filterHardness,
    datasets,
    jobDataLoaded,
    shownAttributes,
  } = useToolContext();
  const [selectedGarments, setSelectedGarments] = useState({});
  const [garmentsSelectedOnPage, setGarmentsSelectedOnPage] = useState(0);
  const [ignoredGarments, setIgnoredGarments] = useState({});
  const [totalAggregations, setTotalAggregations] = useState(0);
  const [totalIgnoredGarments, setTotalIgnoredGarments] = useState(0);
  const [anyDataLoading, setAnyDataLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [similarItemsDataLoading, setSimilarItemsDataLoading] = useState(false);
  const [proposalDataLoading, setProposalDataLoading] = useState(false);
  const [openSearchIndex, setOpenSearchIndex] = useState("vetements");
  const [pagesWithoutTag, setPagesWithoutTag] = useState(0);
  const [tagMadeOnPage, setTagMadeOnPage] = useState(true);
  const [numRecords, setNumRecords] = useState(0);
  const [totalGarmentsSeen, setTotalGarmentsSeen] = useState(0);
  const [imageIndex, setImageIndex] = useState(1);
  const [seedGarmentImages, setSeedGarmentImages] = useState([]);
  const [presentedTimestamp, setPresentedTimestamp] = useState(Date.now())

  const [selectedDataset, setSelectedDataset] = useState(null);
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [numberOfColumns, setNumberOfColumns] = useState(5); // Default to 3 columns
  useEffect(() => {
    setPageSize(numberOfColumns * 3);
    setPage(1);
    setGarments([]);
  }, [numberOfColumns]);

  //Lifecycle Methods
  useEffect(() => {
    setDataLoading(true);
    if (openSearchIndex && jobDataLoaded) {
      fetchData();
    } else {
      setDataLoading(false);
    }
    return () => {
      setTotalAggregations(0);
      setSeedGarment(null);
      setSeedGarmentImages([]);
      setGarmentID(null);
      setPage(1);
      setGarments([]);
      setSelectedGarments([]);
      setGarmentsSelectedOnPage(0);
    };
  }, [openSearchIndex, jobDataLoaded]);

  useEffect(() => {
    setAnyDataLoading(
      dataLoading || similarItemsDataLoading || proposalDataLoading
    );
  }, [dataLoading, similarItemsDataLoading, proposalDataLoading]);

  useEffect(() => {
    if (garmentID) {
      getSimiliarItems();
      setImageIndex(1);
    }
  }, [garmentID, page, pageSize]);

  useEffect(() => {
    if (Object.keys(selectedGarments).length > 0) {
      setTagMadeOnPage(true);
      setPagesWithoutTag(1);
    }
  }, [selectedGarments]);
  const resetState = () => {
    setSeedGarment(null);
    setSeedGarmentImages([]);
    setImageIndex(1);
    setGarmentID(null);
    setPage(1);
    setGarments([]);
    setSelectedGarments([]);
    setGarmentsSelectedOnPage(0);
    setIgnoredGarments({});
    setPagesWithoutTag(0);
  };

  //API Calls
  async function getSimiliarItems() {
    setSimilarItemsDataLoading(true);
    const headers = await getHeaders();
    let data = {
      garment_id: publicGarmentId,
      offset: (page - 1) * pageSize,
      page_size: pageSize,
      index: openSearchIndex,
    };

    await axios
      .post(`${BASE_URL}tools/aggregation/querypinecone`, data, headers)
      .then((res) => {
        setGarments(res.data.data);
        setTotalGarmentsSeen((prev) => prev + garments.length);
        //set ignored garments to all returned garments
        let newIgnoredGarments = Object.assign({}, ignoredGarments);
        res.data.data.forEach((garment, index) => {
          newIgnoredGarments[garment.garment_id] = {
            score: garment.score,
            rank: index + 1 + (page - 1) * pageSize,
            timestamp: new Date().toISOString(),
          };
        });
        setIgnoredGarments(newIgnoredGarments);
        setSimilarItemsDataLoading(false);
        setPresentedTimestamp(Date.now());
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setPagesWithoutTag(3);
        setSimilarItemsDataLoading(false);
      });
  }

  async function fetchData() {
    const start = Date.now();
    let end = Date.now();
    const headers = await getHeaders();
    setSeedGarment(null);
    setGarmentID(null);
    setGarments([]);
    setPage(1);
    setSelectedGarments([]);
    setTagMadeOnPage(false);
    setPagesWithoutTag(0);
    setGarmentsSelectedOnPage(0);
    let url = `${BASE_URL}tools/summon/next-item/basic/aggregation?index=${openSearchIndex}&datasets=${datasets}&pipelineJobId=${jobDetails.pipeline_job_link_id}`;

    axios
      .get(url, headers)
      .then((res) => {
        const { num_images, uuid_public_id } = res.data.data;
        let newImages = [];
        for (let i = 0; i < num_images; i++) {
          const prefix =
            "https://scraped-garment-images.s3.eu-west-2.amazonaws.com/";
          const suffix = `${uuid_public_id}-${i + 1}.jpg`;
          newImages.push(prefix + suffix);
        }
        setSeedGarmentImages(newImages);
        console.log(`AGGTOOL: ${JSON.stringify(res.data.data)}`);
        setSeedGarment({ ...res.data.data, images: newImages });
        setImageIndex(1);
        setPublicGarmentId(res.data.data.uuid_public_id);
        setGarmentID(res.data.data.garment_id);
        end = Date.now();
        console.log(`Execution time: ${end - start} ms`);
        setProposalDataLoading(false);
        setDataLoading(false);
        setPresentedTimestamp(Date.now());
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        end = Date.now();
        console.log(`Execution time: ${end - start} ms`);
        setProposalDataLoading(false);
        setDataLoading(false);
        setSeedGarment(null);
        setPublicGarmentId(null);
        setGarmentID(null);
      });
  }

  //Handlers
  const handleNext = () => {
    if (!tagMadeOnPage) {
      setPagesWithoutTag(pagesWithoutTag + 1);
    }
    setTagMadeOnPage(false);
    setPage(page + 1);
    setGarmentsSelectedOnPage(
      Object.keys(selectedGarments).filter((x) =>
        garments.map((y) => y.garment_id.toString()).includes(x)
      ).length
    );
    console.log(`selectedGarments: ${JSON.stringify(selectedGarments)}`);
    console.log(
      `pagesWithoutTag: ${pagesWithoutTag} garmentsSelected: ${
        Object.keys(selectedGarments).length
      } garmentsSelectedOnPage: ${
        Object.keys(selectedGarments).filter((x) =>
          garments.map((y) => y.garment_id.toString()).includes(x)
        ).length
      }`
    );
    trackEvent("AggregationNextPage", {
      index: openSearchIndex,
      datasetId: selectedDataset?.id,
      garmentId: garmentID,
      aggregations: Object.keys(selectedGarments).length,
      totalAggregations: totalAggregations,
      selectedGarmentIds: Object.keys(selectedGarments),
      rejectedGarmentIds: Object.keys(ignoredGarments),
      jobId: 17,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      pipelineName: jobDetails.pipeline_name,
      jobName: "aggregation",
      toolType: "AGGREGATION",
      toolName: "AggregationTool",
      qa: false,
      pages: page,
    });
  };

  const handleSave = async () => {
    setProposalDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: jobDetails.job_id,
      selected_garments: selectedGarments,
      ignored_garments: ignoredGarments,
      confidence: 0.4,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      presented_timestamp: presentedTimestamp
    };
    const headers = await getHeaders();

    await axios
      .post(`${BASE_URL}tools/classify/basic/aggregation`, data, headers)
      .then(async (res) => {
        setTotalAggregations(
          (prev) =>
            prev +
            (selectedGarments && Object.keys(selectedGarments)
              ? Object.keys(selectedGarments).length
              : 0)
        );
        setTotalIgnoredGarments(
          (prev) => prev + (garments - Object.keys(selectedGarments).length)
        );
        console.log(`selectedGarments: ${JSON.stringify(selectedGarments)}`);
        console.log(
          `pagesWithoutTag: ${pagesWithoutTag} garmentsSelected: ${
            Object.keys(selectedGarments).length
          } garmentsSelectedOnPage: ${
            Object.keys(selectedGarments).filter((x) =>
              garments.map((y) => y.garment_id.toString()).includes(x)
            ).length
          }`
        );
        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations:
            totalAggregations +
            (selectedGarments && Object.keys(selectedGarments)
              ? Object.keys(selectedGarments).length
              : 0),
          selectedGarmentIds: Object.keys(selectedGarments),
          rejectedGarmentIds: Object.keys(ignoredGarments),
          garmentsRejected: Object.keys(ignoredGarments).length,
          garmentsSelected: Object.keys(selectedGarments).length,
          garmentsSeen: totalGarmentsSeen + garments.length,
          jobId: 17,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: false,
        });

        fetchData();
        resetState();
        setTotalGarmentsSeen(0);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setProposalDataLoading(false);
      });
  };

  const handleSkip = async () => {
    setProposalDataLoading(true);
    const data = {
      garment_id: garmentID,
      job_id: 17,
      presented_timestamp: presentedTimestamp
    };
    const headers = await getHeaders();

    await axios
      .post(`${BASE_URL}tools/aggregation/skip`, data, headers)
      .then((res) => {
        setTotalAggregations(
          totalAggregations + (selectedGarments ? selectedGarments.length : 0)
        );
        setTotalIgnoredGarments(
          (prev) => prev + (garments - Object.keys(selectedGarments).length)
        );
        console.log(`selectedGarments: ${JSON.stringify(selectedGarments)}`);
        console.log(
          `pagesWithoutTag: ${pagesWithoutTag} garmentsSelected: ${
            Object.keys(selectedGarments).length
          } garmentsSelectedOnPage: ${
            Object.keys(selectedGarments).filter((x) =>
              garments.map((y) => y.garment_id.toString()).includes(x)
            ).length
          }`
        );
        trackEvent("Aggregation", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: garmentID,
          aggregations: Object.keys(selectedGarments).length,
          totalAggregations:
            totalAggregations +
            (selectedGarments ? selectedGarments.length : 0),
          selectedGarmentIds: Object.keys(selectedGarments),
          rejectedGarmentIds: Object.keys(ignoredGarments),
          garmentsRejected: Object.keys(ignoredGarments).length,
          garmentsSelected: Object.keys(selectedGarments).length,
          garmentsSeen: totalGarmentsSeen + garments.length,
          jobId: 17,
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobName: "aggregation",
          toolType: "AGGREGATION",
          toolName: "AggregationTool",
          qa: false,
          skipped: true,
        });

        setSelectedGarments({});
        setIgnoredGarments({});
        resetState();
        fetchData();
        setTotalGarmentsSeen(0);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
        setProposalDataLoading(false);
      });
  };

  //Renderers
  const renderGarment = (garment, index) => {
    return (
      <GarmentPanel
        garment={garment}
        height={"20vh"}
        xs={12 / numberOfColumns} // Dynamic xs based on the number of columns
        setSelectedGarments={setSelectedGarments}
        setIgnoredGarments={setIgnoredGarments}
        imageIndex={imageIndex}
        ignoredGarments={ignoredGarments}
        selectedGarments={selectedGarments}
        index={index + 1 + (page - 1) * pageSize} // Calculate index based on page and page size
      />
    );
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoadingOverlay open={anyDataLoading} />
      <Box
        sx={{
          height: "50px",
          flexShrink: 0,
          p: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 2,
          }}
        >
          <Typography variant="h2">Aggregation Tool</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={handleNext}
            sx={{ color: theme.palette.primary[100] }}
            disabled={anyDataLoading || pagesWithoutTag == 3}
          >
            Next Page
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginLeft: 2,
            }}
            onClick={() => {
              if (Object.keys(selectedGarments).length > 0) {
                handleSave();
              } else {
                handleSkip();
              }
            }}
            disabled={anyDataLoading || pagesWithoutTag < 3}
          >
            New Garment
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: 60,
          alignContent: "center",
          m: 2,
        }}
      >
        <PineconeIndexDropdown
          index={openSearchIndex}
          setIndex={setOpenSearchIndex}
        />
        <Select
          value={numberOfColumns}
          onChange={(e) => setNumberOfColumns(e.target.value)}
          displayEmpty
          sx={{ ml: 2 }}
        >
          <MenuItem value={4}>4 Column</MenuItem>
          <MenuItem value={5}>5 Columns</MenuItem>
          <MenuItem value={6}>6 Columns</MenuItem>
          <MenuItem value={7}>7 Columns</MenuItem>
          <MenuItem value={8}>8 Columns</MenuItem>
        </Select>
        <ImageIndexButtons index={imageIndex} setIndex={setImageIndex} />
        <Divider
          sx={{
            m: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1, // Fills the remaining space
          display: "flex",
          overflow: "hidden", // Ensures no overflow outside this box
        }}
      >
        <Grid container spacing={0.5} sx={{ ml: 2, height: "100%", overflowY: "scroll"}}>
          {garments.map((garment, index) => renderGarment(garment, index))}
        </Grid>
        <Box sx={{ width: "30vw" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              pl: 2,
            }}
          >
            <Box sx={{ height: "85%" }}>
              <StyledImageCarousel srcs={seedGarmentImages} height={"100%"}/>
            </Box>
            <Typography variant="h4" sx={{my: 1}}>{seedGarment?.title}</Typography>
            <Typography variant="body_sm">
              Session Count: {totalAggregations}
            </Typography>
            <Typography variant="body_sm">
              Currently Selected: {Object.keys(selectedGarments).length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box item xs={12}>
        <Divider
          sx={{
            m: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Typography>Page: {page}</Typography>
          <Typography>Matched Garments: {totalAggregations}</Typography>
          <Typography>Rejected Garments: {totalIgnoredGarments}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

function WrappedAggregationTool() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <AggregationTool />
    </ToolProvider>
  );
}

export default WrappedAggregationTool;
