// StyledDateRangePicker.jsx
import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"; // <-- New imports for date range picker
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
// Create a styled wrapper for future customizations.
// Currently, it doesn't alter any styling.
const DateRangePickerWrapper = styled("div")(({ theme }) => ({
  // TODO: Add custom styles here if needed in the future.
}));

const StyledDateRangePicker = ({
  startText,
  endText,
  value,
  onChange,
  ...otherProps
}) => {
  return (
    <DateRangePickerWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          localeText={{ start: startText, end: endText }}
          value={value}
          onChange={onChange}
          {...otherProps}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} size="small" />
              <Box sx={{ mx: 1 }}> to </Box>
              <TextField {...endProps} size="small" />
            </>
          )}
        />
      </LocalizationProvider>
    </DateRangePickerWrapper>
  );
};

export default StyledDateRangePicker;
