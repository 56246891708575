// OrderByDropdown.js
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const OrderByDropdown = ({ orderBy, setOrderBy }) => {
  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel id="order-by-label">Order By</InputLabel>
      <Select
        labelId="order-by-label"
        id="order-by"
        value={orderBy}
        onChange={(e) => setOrderBy(e.target.value)}
        label="Order By"
      >
        <MenuItem value="DEFAULT">Default</MenuItem>
        <MenuItem value="PROPOSAL_TIMESTAMP_DESC">
          Proposal Timestamp Descending
        </MenuItem>
        <MenuItem value="PROPOSAL_TIMESTAMP_ASC">
          Proposal Timestamp Ascending
        </MenuItem>
        <MenuItem value="QA_TIMESTAMP_DESC">QA Timestamp Descending</MenuItem>
        <MenuItem value="QA_TIMESTAMP_ASC">QA Timestamp Ascending</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrderByDropdown;
